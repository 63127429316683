import { Button, Col, Row } from "antd";
import { EDIT_TRADE_DETAILS_LABELS } from "constants/auditTrail";

import classnames from "classnames";

function BuyOrSellButtons({
    isSellActive,
    setIsSellActive,
    setIsRequestActive,
}) {
    return (
      <Col span={24} style={{ marginBottom: "10px" }}>
        <Row>
          <Col span={12}>
            <Button
              style={{  width: "100%" }}
              onClick={() => {
                setIsRequestActive(true);
                setIsSellActive(false);
              }}
              className={classnames({ "active-button": !isSellActive })}
              key={EDIT_TRADE_DETAILS_LABELS.BUY}
            >
              {EDIT_TRADE_DETAILS_LABELS.BUY}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              style={{ width: "100%" }}
              onClick={() => {
                setIsRequestActive(true);
                setIsSellActive(true);
              }}
              className={classnames({ "active-button": isSellActive })}
              key={EDIT_TRADE_DETAILS_LABELS.SELL}
            >
              {EDIT_TRADE_DETAILS_LABELS.SELL}
            </Button>
          </Col>
        </Row>
      </Col>
    );
}

export default BuyOrSellButtons

import { Col, InputNumber, Row } from "antd";
import { BACKEND_KEYS_NAME } from "constants/editTradeDetails";

function EditTradePremium({
    apiData,
    editTradeValue,
    setEditTradeValue,
    setIsRequestActive,
}) {
    return (
      <Col span={24}>
        <Row>
          <Col span={12}>
            <Row justify={"start"}>
              <Col span={12}>
                <p>{apiData[BACKEND_KEYS_NAME.TRADED_PREMIUM]?.label}</p>
              </Col>
              <Col>
                <InputNumber
                  type="number"
                  onStep={() => setIsRequestActive(true)}
                  onInput={() => setIsRequestActive(true)}
                  onFocus={() => setIsRequestActive(true)}
                  onBlur={() => setIsRequestActive(false)}
                  value={
                    editTradeValue !== undefined
                      ? editTradeValue
                      : apiData[BACKEND_KEYS_NAME.TRADED_PREMIUM]?.premium.value
                  }
                  onChange={(value) => setEditTradeValue(value)}
                  addonAfter={
                    apiData[BACKEND_KEYS_NAME.TRADED_PREMIUM]?.premium.symbol
                  }
                  step={
                    apiData[BACKEND_KEYS_NAME.TRADED_PREMIUM]?.premium
                      .step_size || 0.005
                  }
                  style={{ marginLeft: "auto" }}
                />
              </Col>
            </Row>
          </Col>

          <Col span={12}></Col>
        </Row>
      </Col>
    );
}

export default EditTradePremium
